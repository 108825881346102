import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { EqutumToast, NegativeToast, PositiveToast } from './components/equtum_toast';
import './tailwind.css';
import { ToastMessage } from './types';
import { storage } from './utils/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await storage.getSession(request.headers.get('Cookie'));
  const toastMessage = session.get('toastMessage') as ToastMessage | undefined;

  return json(
    {
      toastMessageFromSession: toastMessage,
      ENV: {
        BACKEND_URL: process.env.BACKEND_URL,
      },
    },
    {
      headers: {
        'Set-Cookie': await storage.commitSession(session),
      },
    }
  );
}

export const meta: MetaFunction = () => {
  return [{ title: 'EQUTUM' }];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  const toastMessageFromSession = data?.toastMessageFromSession;
  const ENV = data?.ENV;

  useEffect(() => {
    const toastData =
      toastMessageFromSession || JSON.parse(sessionStorage.getItem('toastMessage') || 'null');

    if (toastData) {
      const showToast =
        toastData.type === 'success'
          ? () => toast.success(<PositiveToast message={toastData.content} />)
          : () => toast.error(<NegativeToast message={toastData.content} />);

      showToast();

      if (!toastMessageFromSession) {
        sessionStorage.removeItem('toastMessage');
      }
    }
  }, [toastMessageFromSession]);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body className="font-body">
        <EqutumToast />
        <MantineProvider>{children}</MantineProvider>
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
